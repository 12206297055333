@import '~antd/dist/antd.css';

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --mdc-theme-primary: #0045a5;
  --mdc-theme-secondary: #0045a5;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

.ant-checkbox-wrapper .ant-checkbox,
.ant-radio-wrapper .ant-radio {
  top: 0;
}
